import { Fancybox } from '@fancyapps/ui'
import { validate } from '../../base/js/core'

const options = {
	showClass: () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

		return width > 767 ? 'f-fadeIn' : 'f-inDown'
	},
	hideClass: () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

		return width > 767 ? 'f-fadeOut' : 'f-outDown'
	},
	on: {
		done: () => {
			validate('.b-popup .js-validate [required]')
		}
	}
}
Fancybox.bind('[data-fancybox]', options)
document.addEventListener('click', (e) => {
	if (e.target.closest('.b-popup') && e.target.dataset.fancybox) {
		e.target.closest('.b-popup').querySelector('[data-fancybox-close]').click();
	}
});
