import Swiper, { Navigation, Thumbs, FreeMode, Pagination } from 'swiper'

const productDetail = document.querySelector('#product-detail');
if (productDetail) {
	const thumbs = productDetail.querySelector('.s-product-detail__thumbs-swiper');
	const slider = productDetail.querySelector('.s-product-detail__slider .swiper');
	const $prev = productDetail.querySelector('.swiper-button-prev');
	const $next = productDetail.querySelector('.swiper-button-next');
	const swiperThumbs = new Swiper(thumbs, {
		modules: [Navigation, Thumbs, FreeMode],
		direction: 'vertical',
		spaceBetween: 8,
		slidesPerView: 6,
		freeMode: true,
		watchSlidesProgress: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		breakpoints: {
			320: {
				slidesPerView: 3
			},
			992: {
				slidesPerView: 3
			},
			1400: {
				slidesPerView: 5
			}
		}
	});
	const swiper = new Swiper(slider, {
		modules: [Navigation, Thumbs, FreeMode, Pagination],
		spaceBetween: 0,
		grabCursor: true,
		thumbs: {
			swiper: swiperThumbs
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		}
	});
	const swiperThumbsHeight = () => {
		swiper.update();
		const heightSlider = slider.offsetHeight - 48;
		thumbs.style.height = `${heightSlider}px`;
		swiperThumbs.update();
	}
	swiperThumbsHeight()
	1
}
const buySticky = () => {
	const $buySticky = document.getElementById('buy-sticky')
	const $buyPrice = document.querySelector('.s-product-detail__buy');
	if ($buySticky && $buyPrice) {
		const onScroll = (entries, observer) => {
			entries.forEach(entry => {
				$buySticky.classList.toggle('is-visible', !entry.isIntersecting)
			})
		}
		const observer = new IntersectionObserver(onScroll, {
			rootMargin: '-120px 0px '
		})
		observer.observe($buyPrice)
	}
}
buySticky()
