import Swiper, { Pagination } from 'swiper';
import { breakpoints, getSiblings } from '../../base/js/core'
const toggleTxt = ($block) => {
	const staticHeight = 101;
	const arrBtns = $block.querySelectorAll('.b-reviews-carousel__more-lnk');
	arrBtns.forEach($btn => {
		const arrSiblSlide = getSiblings($btn.closest('.swiper-slide'));
		const $desc = $btn.closest('.swiper-slide').querySelector('.b-reviews-carousel__review');
		const $box = $btn.closest('.b-reviews-carousel__box')
		const $wrapper = $btn.closest('.b-reviews-carousel')
		$desc.style.height = 'auto';
		let originHeight = $desc.offsetHeight;
		let heightDiff = originHeight - staticHeight;
		$desc.style.height = `${staticHeight}px`;
		window.addEventListener('resize', () => {
			$desc.style.height = 'auto';
			originHeight = $desc.offsetHeight;
			heightDiff = originHeight - staticHeight;
			$desc.style.height = `${staticHeight}px`;
		});
		$btn.addEventListener('click', (e) => {
			e.preventDefault();
			$btn.classList.toggle('is-active');
			if ($btn.classList.contains('is-active')) {
				$desc.style.height = `${originHeight}px`;
				if ($block.classList.contains('swiper-initialized')) {
					arrSiblSlide.forEach($slide => {
						const $btnS = $slide.querySelector('.b-reviews-carousel__more-lnk.is-active');
						if ($btnS) {
							$btnS.click();
						}
					})
					$wrapper.style.paddingBottom = `${heightDiff}px`;
					$wrapper.style.marginBottom = `-${heightDiff}px`;
					$box.style.marginBottom = `-${heightDiff}px`;
				}
			} else {
				$desc.style.height = `${staticHeight}px`;
				if ($block.classList.contains('swiper-initialized')) {
					$wrapper.style.paddingBottom = '0px';
					$wrapper.style.marginBottom = '-0px';
					$box.style.marginBottom = '0px';
				}
			}
		});
	})
}
const arrReviews = document.querySelectorAll('.b-reviews-carousel')
arrReviews.forEach($sCarousel => {
	const $carousel = $sCarousel.querySelector('.swiper');
	const $pagination = $sCarousel.querySelector('.swiper-pagination');
	const options = {
		modules: [Pagination],
		loop: false,
		slidesPerView: 1,
		spaceBetween: 0,
		pagination: {
			el: $pagination,
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1.2
			},
			768: {
				slidesPerView: 2.7
			},
			992: {
				slidesPerView: 2.7
			},
			1450: {
				slidesPerView: 2.7
			}
		}
	}
	let swiper = new Swiper($carousel, options);
	const swiperResize = () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width < breakpoints.tablet) {
			swiper.destroy(false, true);
		} else {
			swiper = new Swiper($carousel, options);
		}
	}
	swiperResize();
	window.addEventListener('resize', () => swiperResize());
	toggleTxt($carousel);
});
