const arrToggleReviews = document.querySelectorAll('.js-reviews-toggle-anchore')
arrToggleReviews.forEach($btn => {
	const $reviews = document.getElementById('reviews');
	const html = document.querySelector('html');
	$btn.addEventListener('click', (e) => {
		e.preventDefault();
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const display = window.getComputedStyle($reviews).display
		if (width < 992) {
			console.log('<')
			if (display === 'none') {
				$reviews.style.display = 'block'
			}
			html.classList.toggle('is-reviews-open')
			if (html.classList.contains('is-reviews-open')) {
				$reviews.querySelector('.b-reviews').classList.remove('is-out-in-left');
				$reviews.querySelector('.b-reviews').classList.toggle('is-fade-in-left');
			} else {
				$reviews.querySelector('.b-reviews').classList.remove('is-fade-in-left');
				$reviews.querySelector('.b-reviews').classList.toggle('is-out-in-left');
			}
		} else {
			const headerH = width < 992 ? document.querySelector('.s-header__top').offsetHeight + 61 : document.querySelector('.s-header__catalog-menu').offsetHeight + 40;
			if (display === 'none') {
				$reviews.style.display = 'block'
			}
			const elPosition = $reviews.getBoundingClientRect().top - headerH;
			window.scrollBy({
				top: elPosition,
				behavior: 'smooth'
			});
		}
	})
})
const arrCloseReviews = document.querySelectorAll('.js-close-reviews');
arrCloseReviews.forEach($btn => {
	const html = document.querySelector('html');
	const $reviews = document.getElementById('reviews');
	$btn.addEventListener('click', (e) => {
		e.preventDefault();
		const display = window.getComputedStyle($reviews).display
		if (display === 'none') {
			$reviews.style.display = 'block'
		}
		$reviews.querySelector('.b-reviews').classList.remove('is-fade-in-left');
		$reviews.querySelector('.b-reviews').classList.toggle('is-out-in-left');
		html.classList.remove('is-reviews-open');
	})
})
