import Swiper, { Navigation, Pagination } from 'swiper';
const arrSliders = document.querySelectorAll('.s-main-banner__swiper');
arrSliders.forEach($slider => {
	const $prev = $slider.closest('.s-main-banner').querySelector('.swiper-button-prev');
	const $next = $slider.closest('.s-main-banner').querySelector('.swiper-button-next');
	const $pagination = $slider.querySelector('.swiper-pagination');
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper($slider, {
		modules: [Navigation, Pagination],
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		pagination: {
			el: $pagination,
			clickable: true
		}
	});
})

