import { breakpoints, slideToggle } from '../../base/js/core'

const arrSubmenuToggle = document.querySelectorAll('.js-submenu-toggle');
arrSubmenuToggle.forEach($btn => {
	$btn.addEventListener('click', (e) => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width < breakpoints.desktop) {
			e.preventDefault();
			const $dropdown = $btn.closest('.b-main-menu__itm').querySelector('.b-main-menu__submenu');
			$btn.classList.toggle('is-active')
			slideToggle($dropdown)
		}
	})
})
