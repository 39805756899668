import { slideToggle } from '../../base/js/core'

const arrBtnReview = document.querySelectorAll('.b-card-review__answer-btn')
arrBtnReview.forEach($btn => {
	const $form = $btn.closest('.b-card-review__btm').nextElementSibling;
	$btn.addEventListener('click', (e) => {
		e.preventDefault();
		slideToggle($form)
	})
})
