import Swiper, { Navigation, Pagination } from 'swiper';
const arrProductCarousel = document.querySelectorAll('.b-product-carousel')
arrProductCarousel.forEach($sCarousel => {
	const $prev = $sCarousel.querySelector('.swiper-button-prev');
	const $next = $sCarousel.querySelector('.swiper-button-next');
	const $carousel = $sCarousel.querySelector('.swiper');
	const arrSlide = $sCarousel.querySelectorAll('.swiper-slide');
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper($carousel, {
		modules: [Navigation, Pagination],
		loop: $sCarousel.dataset.loop === 'false' ? false : true,
		slidesPerView: 1,
		spaceBetween: 0,
		breakpoints: {
			320: {
				slidesPerView: $sCarousel.dataset.perMobileSmall || 1.2
			},
			375: {
				slidesPerView: $sCarousel.dataset.perMobile || 1.2
			},
			768: {
				slidesPerView: $sCarousel.dataset.perTablet || 3.2
			},
			992: {
				slidesPerView: $sCarousel.dataset.perDesktop || 3.2
			},
			1400: {
				slidesPerView: $sCarousel.dataset.perDesktopLg || 4
			}
		},
		navigation: {
			nextEl: $next,
			prevEl: $prev
		}
	});
	arrSlide.forEach($slide => {
		$slide.addEventListener('mouseenter', () => {
			$carousel.style.zIndex = '1';
		});
		$slide.addEventListener('mouseleave', () => {
			$carousel.style.zIndex = 'auto';
		});
	})
})

