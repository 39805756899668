import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper'
const arrCardOrder = document.querySelectorAll('.b-card-order')
arrCardOrder.forEach($cardOrder => {
	const $carousel = $cardOrder.querySelector('.swiper');
	const $scrollBar = $cardOrder.querySelector('.swiper-scrollbar');
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper($carousel, {
		modules: [Scrollbar],
		slidesPerView: 2.2,
		spaceBetween: 20,
		scrollbar: {
			draggable: true,
			el: $scrollBar
		},
		breakpoints: {
			320: {
				slidesPerView: 2
			},
			375: {
				slidesPerView: 2.5
			},
			768: {
				slidesPerView: 2
			},
			992: {
				slidesPerView: 2
			},
			1200: {
				slidesPerView: 2.5
			},
			1300: {
				slidesPerView: 3
			},
			1500: {
				slidesPerView: 4
			}
		}
	});
})

