import Swiper, { Navigation, Thumbs, FreeMode, Scrollbar, Mousewheel } from 'swiper'

document.addEventListener('DOMContentLoaded', () => {
	const quickView = document.querySelector('#quick-view');
	if (quickView) {
		const thumbs = quickView.querySelector('.b-quick-view__thumbs-swiper');
		const slider = quickView.querySelector('.b-quick-view__slider .swiper');
		const $prev = quickView.querySelector('.swiper-button-prev');
		const $next = quickView.querySelector('.swiper-button-next');
		const $scrollScrollBar = quickView.querySelector('.b-quick-view__desc .swiper');
		const $scrollBar = quickView.querySelector('.b-quick-view__desc .swiper-scrollbar');

		const swiperThumbs = new Swiper(thumbs, {
			modules: [Navigation, Thumbs, FreeMode],
			direction: 'vertical',
			spaceBetween: 8,
			slidesPerView: 6,
			freeMode: true,
			watchSlidesProgress: true,
			navigation: {
				nextEl: $next,
				prevEl: $prev
			}
		});
		const swiper = new Swiper(slider, {
			modules: [Navigation, Thumbs, FreeMode],
			spaceBetween: 0,
			grabCursor: true,
			thumbs: {
				swiper: swiperThumbs
			}
		});
		const swiperBar = new Swiper($scrollScrollBar, {
			modules: [Scrollbar, FreeMode, Mousewheel],
			direction: 'vertical',
			slidesPerView: 'auto',
			freeMode: true,
			scrollbar: {
				el: $scrollBar
			},
			mousewheel: true
		});
	}
});
