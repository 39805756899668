import { breakpoints, slideToggle } from '../../base/js/core'

const arrMenuToggle = document.querySelectorAll('.js-catalog-submenu-toggle');
let flag = true;
let flagMenuOpen = false
let clearSetTimeout = null;
let clearSetTimeoutS = null;
arrMenuToggle.forEach($btn => {
	const $parentItm = $btn.closest('.b-catalog-menu__itm');
	$btn.addEventListener('click', (e) => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const $submenu = $btn.parentNode.querySelector('.b-catalog-menu__submenu') || $btn.parentNode.querySelector('.b-catalog-menu__subsection')
		if (width < breakpoints.desktop) {
			e.preventDefault();
			$btn.parentNode.classList.toggle('is-open');
			if ($submenu) {
				slideToggle($submenu)
			}
		}
	});
	$parentItm.addEventListener('mouseenter', () => {
		flag = true;
		clearTimeout(clearSetTimeoutS);
		clearTimeout(clearSetTimeout);
		if (flagMenuOpen) {
			$parentItm.classList.add('is-hover');
			flagMenuOpen = true;
		} else {
			clearSetTimeout = setTimeout(() => {
				if (flag) {
					$parentItm.classList.add('is-hover');
					flagMenuOpen = true;
				}
			}, 300)
		}
	});
	$parentItm.addEventListener('mouseleave', () => {
		flag = false;
		$parentItm.classList.remove('is-hover');
		clearTimeout(clearSetTimeoutS);
		clearSetTimeoutS = setTimeout(() => {
			flagMenuOpen = false;
		}, 300)
	});
})
