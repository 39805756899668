const $timer = document.getElementById('timer');

if ($timer) {
	const timer = setInterval(() => {
		const countDownDate = new Date($timer.dataset.date).getTime();
		const now = new Date().getTime();
		const distance = countDownDate - now;
		const $days = document.getElementById('days');
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		if ($days) {
			$days.textContent = days
		}
		const $hours = document.getElementById('hours');
		const hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
		if ($hours) {
			$hours.textContent = hours
		}
		const $minutes = document.getElementById('min');
		const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
		if ($minutes) {
			$minutes.textContent = minutes
		}
		const $seconds = document.getElementById('seconds');
		const seconds = Math.floor(distance % (1000 * 60) / 1000);
		if (seconds) {
			$seconds.textContent = seconds
		}
		// If the count down is finished, write some text
		if (distance < 0) {
			clearInterval(timer);
		}
	}, 60000);
	timer();
}
