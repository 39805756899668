import Swiper, { Navigation, EffectCoverflow } from 'swiper'
const arrSlider = document.querySelectorAll('.s-slider');
arrSlider.forEach($sSlider => {
	const $slider = $sSlider.querySelector('.swiper');
	const $prev = $sSlider.querySelector('.swiper-button-prev');
	const $next = $sSlider.querySelector('.swiper-button-next');
	const swiper = new Swiper($slider, {
		modules: [Navigation, EffectCoverflow],
		loop: true,
		slidesPerView: 3,
		centeredSlides: true,
		effect: 'coverflow',
		spaceBetween: 0,
		coverflowEffect: {
			rotate: 0,
			scale: 0.6,
			stretch: 0,
			depth: 0,
			modifier: 1,
			slideShadows: false
		},
		grabCursor: true,
		parallax: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: -35
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 0
			}
		}
	});
})
