import { Fancybox } from '@fancyapps/ui'

const arrMore = document.querySelectorAll('.b-filter__more-lnk');
arrMore.forEach($btn => {
	const arrHidden = $btn.closest('.b-filter__list').querySelectorAll('.b-filter__itm_hide');
	$btn.addEventListener('click', (e) => {
		e.preventDefault();
		$btn.classList.toggle('is-active');
		arrHidden.forEach($itm => {
			$itm.classList.toggle('is-show')
		})
	})
})
const arrFilterToggle = document.querySelectorAll('.js-filter');
arrFilterToggle.forEach($btn => {
	$btn.addEventListener('click', () => {

	})
})
const options = {
	showClass: 'f-inDown',
	hideClass: 'f-outDown',
	mainClass: 'mainclass'
}
Fancybox.bind('.js-filter', options);
