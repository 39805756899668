import { breakpoints } from '../../base/js/core'

const btnMenuToggle = document.querySelectorAll('.js-menu-toggle');
const $html = document.querySelector('html');
const $mainMenu = document.getElementById('main-menu');
const menuOpen = () => {
	$html.classList.add('is-main-menu-open')
	$mainMenu.classList.add('is-open')
}
const menuClose = () => {
	$html.classList.remove('is-main-menu-open')
	$mainMenu.classList.remove('is-open')
}
if ($mainMenu) {
	btnMenuToggle.forEach($btn => {
		$btn.addEventListener('click', (e) => {
			e.preventDefault();
			if ($mainMenu.classList.contains('is-open')) {
				menuClose();
			} else {
				menuOpen();
			}
		})
	});
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.s-header__menu-bar') && !e.target.closest('.js-menu-toggle')) {
			menuClose();
		}
	});
	const arrToggleCatalogSearch = document.querySelectorAll('.js-toggle-catalog-search');
	const catalogMenu = document.getElementById('search-catalog');
	arrToggleCatalogSearch.forEach($btn => {
		$btn.addEventListener('click', (e) => {
			e.preventDefault();
			$btn.classList.toggle('is-active');
			$html.classList.toggle('is-catalog-search-open');
			catalogMenu.classList.toggle('is-open')
		})
	});
	const catalogSearchClose = () => {
		$html.classList.remove('is-catalog-search-open');
		catalogMenu.classList.remove('is-open');
		arrToggleCatalogSearch.forEach($btn => {
			$btn.classList.remove('is-active');
		});
	}
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.s-header__menu-bar') && !e.target.closest('.js-menu-toggle')) {
			menuClose();
		}
		if (!e.target.closest('.s-header__btm') && !e.target.closest('.js-toggle-catalog-search')) {
			catalogSearchClose();
		}
	});

	const headerScroll = () => {
		const $header = document.querySelector('.s-header');
		const $headerFixedBlock = $header.querySelector('.s-header__catalog-menu');
		const $headerSearch = $header.querySelector('.s-header__search');
		const onScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			let headerBottom = $headerSearch.offsetTop + $headerSearch.offsetHeight + $headerFixedBlock.offsetHeight;
			const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			if (scrollTop > headerBottom && width > breakpoints.desktop) {
				$header.style.height = `${headerBottom}px`;
				$header.classList.add('is-fixed');
			} else {
				$header.style.height = 'auto';
				$header.classList.remove('is-fixed');
			}
		}
		window.addEventListener('scroll', () => onScroll());
		window.addEventListener('resize', () => onScroll());
	};
	headerScroll()
}
