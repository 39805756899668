import Swiper, { Pagination } from 'swiper';
const arrVideos = document.querySelectorAll('.b-video-carousel')
arrVideos.forEach($sCarousel => {
	const $carousel = $sCarousel.querySelector('.swiper');
	const $pagination = $sCarousel.querySelector('.swiper-pagination');
	// eslint-disable-next-line no-unused-vars
	const swiper = new Swiper($carousel, {
		modules: [Pagination],
		loop: false,
		slidesPerView: 1,
		spaceBetween: 0,
		pagination: {
			el: $pagination,
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1.2
			},
			768: {
				slidesPerView: 3.5
			},
			992: {
				slidesPerView: 3.5
			},
			1450: {
				slidesPerView: 3
			}
		}
	});
})

