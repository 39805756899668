import { prevAll } from '../../base/js/core'

const arrRating = document.querySelectorAll('.b-form-review__rating')
arrRating.forEach($rating => {
	const arrRadio = $rating.querySelectorAll('.b-form-review__rating-input');
	const arrLabel = $rating.querySelectorAll('.b-form-review__rating-label');
	const checked = () => {
		arrRadio.forEach($radio => {
			if ($radio.checked) {
				const arrPrevEl = prevAll($radio.nextElementSibling)
				$radio.nextElementSibling.classList.add('is-checked');
				arrPrevEl.forEach($el => {
					$el.classList.add('is-checked');
				})
			}
		})
	}
	checked();
	arrLabel.forEach($label => {
		const arrPrevEl = prevAll($label)
		$label.addEventListener('mouseenter', () => {
			arrLabel.forEach($el => {
				$el.classList.remove('is-checked');
			})
			$label.classList.add('is-hover');
			arrPrevEl.forEach($el => {
				$el.classList.add('is-hover');
			})
		})
		$label.addEventListener('mouseleave', () => {
			arrLabel.forEach($el => {
				$el.classList.remove('is-hover');
			});
			checked();
		})
	})
})

