import { getSiblings } from '../../base/js/core'

const arrCardPager = document.querySelectorAll('.b-card-product__pager-itm');
const arrCard = document.querySelectorAll('.b-card-product');
arrCardPager.forEach($cardPager => {
	const $img = document.getElementById($cardPager.dataset.img.substr(1));
	const arrImgS = getSiblings($cardPager);
	$cardPager.addEventListener('mouseenter', () => {
		$cardPager.classList.add('is-active');
		$img.classList.add('is-visible')
		arrImgS.forEach($sCardPager => {
			const $imgS = document.getElementById($sCardPager.dataset.img.substr(1));
			$sCardPager.classList.remove('is-active');
			$imgS.classList.remove('is-visible')
		})
	})
});
arrCard.forEach($card => {
	const firstPager = $card.querySelector('.b-card-product__pager-itm:first-child');
	if (firstPager) {
		$card.addEventListener('mouseleave', () => {
			let event = new Event('mouseenter');
			firstPager.dispatchEvent(event);
		})
	}
})
