import './s-header/s-header';
import './b-main-menu/b-main-menu';
import './b-panel/b-panel';
import './b-catalog-menu/b-catalog-menu';
import './b-search/b-search';
import './s-main-banner/s-main-banner';
import './b-product-carousel/b-product-carousel';
import './b-card-product/b-card-product';
import './s-slider/s-slider';
import './s-sections/s-sections';
import './b-popup/b-popup';
import './b-quick-view/b-quick-view';
import './b-filter-sort/b-filter-sort';
import './b-filter/b-filter';
import './b-range-slider/b-range-slider';
import './s-product-detail/s-product-detail';
import './b-reviews/b-reviews';
import './b-card-review/b-card-review';
import './b-form-review/b-form-review';
import './b-card-order/b-card-order';
import './s-action/s-action';
import './b-video-carousel/b-video-carousel';
import './b-reviews-carousel/b-reviews-carousel';

