import noUiSlider from 'nouislider';
if (document.getElementById('sliderrange')) {
	const $slider = document.getElementById('sliderrange');
	const $filterReset = document.getElementById('filter-reset')
	const min = parseInt($slider.dataset.min, 10);
	const max = parseInt($slider.dataset.max, 10);
	const start = []
	const $inputMin = $slider.closest('.b-range-slider').querySelector('#sliderrange-min input');
	const $inputMax = $slider.closest('.b-range-slider').querySelector('#sliderrange-max input');
	start[0] = $slider.dataset.start;
	start[1] = $slider.dataset.end;
	const inputs = [$inputMin, $inputMax];
	noUiSlider.create($slider, {
		start,
		connect: true,
		format: {
			// 'to' the formatted value. Receives a number.
			to: value => {
				return Math.ceil(value);
			},
			from: value => {
				return Math.ceil(value);
			}
		},
		range: {
			min,
			max
		}
	});
	$slider.noUiSlider.on('update', (values, handle) => {
		inputs[handle].value = values[handle];
	});

	inputs.forEach((input, handle) => {
		input.addEventListener('change', () => {
			console.log(input, handle)
			$slider.noUiSlider.setHandle(handle, input.value);
		});

		input.addEventListener('keydown', (e) => {
			let values = $slider.noUiSlider.get();
			let value = Number(values[handle]);
			let steps = $slider.noUiSlider.steps();
			let step = steps[handle];
			let position;

			// eslint-disable-next-line default-case
			switch (e.which) {
				case 13:
					$slider.noUiSlider.setHandle(handle, input.value);
					break;
				case 38:
					position = step[1];
					if (position === false) {
						position = 1;
					}
					if (position !== null) {
						$slider.noUiSlider.setHandle(handle, value + position);
					}
					break;
				case 40:
					position = step[0];
					if (position === false) {
						position = 1;
					}
					if (position !== null) {
						$slider.noUiSlider.setHandle(handle, value - position);
					}
					break;
			}
		});
	});
	if ($filterReset) {
		$filterReset.addEventListener('click', () => {
			inputs.forEach((input, handle) => {
				$slider.noUiSlider.setHandle(handle, 0);
			});
		});
	}

}
