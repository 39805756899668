import { slideToggle, slideUp } from '../../base/js/core'

const arrSort = document.querySelectorAll('.sort');
arrSort.forEach($sort => {
	const $head = $sort.querySelector('.sort__head');
	const $dropdown = $sort.querySelector('.sort__dropdown');
	const $headTxt = $head.querySelector('span');
	const arrRadio = $sort.querySelectorAll('input[type="radio"]');
	const arrItm = $sort.querySelectorAll('.sort__itm');

	$head.addEventListener('click', () => {
		$sort.classList.toggle('is-open');
		slideToggle($dropdown)
	});

	arrRadio.forEach($radio => {
		$radio.addEventListener('change', () => {
			if ($radio.checked) {
				arrItm.forEach($itm => {
					$itm.classList.remove('sort__itm_hide')
				})
				$radio.parentNode.classList.add('sort__itm_hide')
				$headTxt.textContent = $radio.value;
				$sort.classList.remove('is-open');
				slideUp($dropdown)
			}
		})
	})
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.sort')) {
			$sort.classList.remove('is-open');
			slideUp($dropdown)
		}
	});
})
