import Swiper from 'swiper'
const arrSlider = document.querySelectorAll('.s-sections');
arrSlider.forEach($sSlider => {
	const $slider = $sSlider.querySelector('.swiper');

	const swiper = new Swiper($slider, {
		loop: true,
		slidesPerView: 1,
		spaceBetween: 0,
		grabCursor: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: -55,
				centeredSlides: true
			},
			480: {
				slidesPerView: 2,
				spaceBetween: 0,
				centeredSlides: true
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 0,
				centeredSlides: false
			},
			992: {
				slidesPerView: 4,
				spaceBetween: 0,
				centeredSlides: false
			},
			1400: {
				slidesPerView: 5,
				spaceBetween: 0,
				centeredSlides: false
			}
		}
	});
})
