arrSearchInput = document.querySelectorAll('.js-search');

arrSearchInput.forEach($search => {
	const $parent = $search.closest('.b-search');
	const arrResetBtn = $parent.querySelectorAll('[type="reset"]');
	$search.addEventListener('focus', () => {
		$parent.classList.add('is-focus');

		if ($search.closest('.s-header__search')) {
			$search.closest('.s-header__search').classList.add('is-search-focus')
		}
	})
	$search.addEventListener('blur', () => {
		$parent.classList.remove('is-focus');
		$parent.classList.remove('is-open')
		if ($search.closest('.s-header__search')) {
			$search.closest('.s-header__search').classList.remove('is-search-focus')
		}
	})
	arrResetBtn.forEach($btn => {
		$btn.addEventListener('click', () => {
			$parent.classList.remove('is-autocomplete-open')
		})
	})

	$search.addEventListener('input', () => {
		const l = Number($search.dataset.showAutocompleteLetter) || 0;
		if ($search.value.length > l) {
			$parent.classList.add('is-autocomplete-open')
		} else {
			$parent.classList.remove('is-autocomplete-open')
		}
	})
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.b-search')) {
			$parent.classList.remove('is-autocomplete-open')
		}
	});
})
